<template>
    <div class="top_cards">
        <balance-card class="top_card balance_card"></balance-card>
        <address-card class="top_card addr_card"></address-card>
    </div>
</template>
<script>
import BalanceCard from './TopCards/BalanceCard/BalanceCard'
import AddressCard from './TopCards/AddressCard/AddressCard'

export default {
    components: {
        BalanceCard,
        AddressCard,
    },
}
</script>
<style scoped lang="scss">
@use '../../main';

html[data-theme='night'] {
    .top_cards {
    }
}

.top_cards {
    display: grid;
    grid-template-columns: 1fr 360px;
    grid-gap: 15px;
}
.top_card {
    flex-grow: 1;
    color: var(--font);
    flex-shrink: 0;
    //display: flex;
    border-radius: 5px;
    padding: 12px 20px;
    overflow: hidden;
    border-radius: 5px;
    animation-name: fade;
    animation-duration: 0.6s;
    animation-timing-function: ease-out;
}

.balance_card,
.addr_card {
    background-color: var(--bg-wallet) !important;
    // backdrop-filter: blur(50px);
    // box-shadow: 0px 90.9961px 72.7969px rgba(41, 72, 152, 0.05),
    //     0px 58.9789px 42.6334px rgba(41, 72, 152, 0.037963),
    //     0px 35.0503px 23.1872px rgba(41, 72, 152, 0.0303704),
    //     0px 18.1992px 11.8295px rgba(41, 72, 152, 0.025),
    //     0px 7.4145px 5.9316px rgba(41, 72, 152, 0.0196296),
    //     0px 1.68511px 2.86469px rgba(41, 72, 152, 0.012037);
    border-radius: 8px;
}
.card_left {
    background-color: #f4efff;
    flex-basis: 70px;
    flex-shrink: 0;
    padding: 15px;
    border-bottom-right-radius: 5px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
        fill: #ddd;
    }
}

.card_right {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 20px 18px;
    padding-bottom: 8px;
}

.top_card h4 {
    color: main.$primary-color;
    font-weight: bold;
    text-align: left;
}
.top_card p {
    word-break: break-all;
    text-align: left;
    flex-grow: 1;
    margin: 0;
    font-size: 14px;
    font-family: 'Manrope', sans-serif;
}

.balance {
    font-size: 24px !important;
    font-family: 'Manrope', sans-serif;
}

.top_card .buts {
    width: 100%;
    text-align: right;
}
.top_card .buts button {
    font-size: 18px;
    margin: 0px 18px;
    margin-right: 0px;
    position: relative;
    outline: none;
}

.buts img {
    height: 20px;
    width: 20px;
    object-fit: contain;
}

@media only screen and (max-width: main.$mobile_width) {
}

@include main.medium-device {
    .top_cards {
        grid-template-columns: 1fr 320px;
        grid-gap: 9px;
    }
}

@include main.mobile-device {
    .top_cards {
        grid-template-columns: none;
        grid-template-rows: min-content;
        grid-gap: 9px;
    }

    .top_card {
        /*padding: ;*/
    }

    .balance_card {
        grid-column: unset;
    }

    .addr_card {
        grid-column: unset;
    }
}

//@media only screen and (max-width: main.$width_m) {
//
//}
</style>
